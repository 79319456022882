.social-btn {
	height: 100%;
	flex: 1;
	border-right: 1px solid #eaedf3;
	padding-top: 3px;

	&:last-child {
		border-right: none;
	}

	&#slack {
		&:before {
			content: url('../assets/images/slack.svg');
		}

		&:after {
			content: url('../assets/images/slack-dark.svg');
			display: none;
		}

		&:hover:before {
			display: none;
		}

		&:hover:after {
			display: block;
		}
	}

	&#facebook {
		&:before {
			content: url('../assets/images/facebook.svg');
		}

		&:after {
			content: url('../assets/images/facebook-dark.svg');
			display: none;
		}

		&:hover:before {
			display: none;
		}

		&:hover:after {
			display: block;
		}
	}

	&#google {
		&:before {
			content: url('../assets/images/google.svg');
		}

		&:after {
			content: url('../assets/images/google-dark.svg');
			display: none;
		}

		&:hover:before {
			display: none;
		}

		&:hover:after {
			display: block;
		}
	}
}
