.notification-container-top-right {
	right: 121px !important; // 44px
	top: 48px !important;

	@media (max-width: 768px) {
		top: 36px !important;
	}

	@media (max-width: 576px) {
		right: 7% !important;
	}
}

.notification-item.notification-item-child {
	box-shadow: none !important;
}

.notification-custom {
	width: 100%;
	min-width: 353px;
	display: flex;
	background-color: #f24236;
	height: 48px;
	align-items: center;
	padding: 16px;
	position: relative;

	@media (max-width: 576px) {
		min-width: unset;
	}

	.notification-custom-icon {
		margin-left: -2px;
		margin-right: 16px;
	}

	.notification-custom-content {
		flex: 1;
		font-size: 22px;
		font-weight: bold;
		margin-top: -4px;
	}

	.notification-custom-close-icon {
		position: absolute;
		right: 14px;

		img {
			transform: rotate(45deg);
		}
	}
}

.notification-custom-danger {
	background-color: #f24236;
}

.notification-custom-success {
	background-color: #09bc8a;
}
