body,
html {
	margin: 0;
	padding: 0;
	font-family: 'Roboto', sans-serif;
	background: #fbfbfd;
}
* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
	color: #2a5c7a;

	&:hover {
		color: #13324a;
	}
}
