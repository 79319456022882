.app-container {
	justify-content: center;
	align-items: center;
	height: 100vh;

	.uik-widget__wrapper,
	.uik-input__input,
	.uik-btn__base {
		border-radius: 0px;
	}

	.login-box {
		width: 445px;
		min-height: 457px;
		position: relative;

		@media (max-width: 576px) {
			width: auto;
		}
	}

	.content-container {
		padding: 40px 40px 60px;
	}

	.secondary-text {
		color: #9ea0a5;
	}

	.primary-btn {
		color: white;
		background: #2a5c7a;
		height: 50px;

		&:hover {
			background: #13324a;
		}
	}

	p {
		font-size: 0.875rem;

		span {
			color: #2a5c7a;
		}
	}

	.center-text {
		text-align: center;
	}

	.uik-input__errorMessage {
		margin-bottom: 0px !important;
	}

	.subtext {
		@media (max-width: 576px) {
			width: 250px;
		}
	}
}
